<template>
  <a-week-picker format="YYYY-MM-DD" allow-clear @change="onChange">
    <span class="weekBox">{{ time? time : '请选择周' }}</span>
  </a-week-picker>
</template>
<script>
import moment from 'moment'
export default {
  name: 'DateWeek',
  data() {
    return {
      time: ''
    }
  },
  mounted() {
    this.onChange(new Date())
  },
  methods: {
    onChange(value) {
      const weekOfday = moment(value).format('E')
      const monday = moment(value).subtract(weekOfday - 1, 'days').format('YYYY-MM-DD')
      const sunday = moment(value).subtract(weekOfday - 7, 'days').format('YYYY-MM-DD')
      this.time = monday + ' ~ ' + sunday
      this.$emit('change', [monday, sunday], this.time)
    }
  }
}
</script>
<style lang="less" scoped>
.weekBox{
    display: inline-block;
    width: 250px;
    height: 0.16667rem;
    padding: 0.02083rem 0.05729rem;
    color: rgba(0, 0, 0, 0.65);
    font-size: 0.07292rem;
    line-height: 1.5;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 0.02083rem;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
}
</style>
