<template>
  <div>
    <slot name="icon" />
    <div v-if="visible" class="modal">
      <div class="main">
        <div class="headerBox">
          <div class="title">站内信</div>
          <a-icon class="close" type="close" @click="visible = false" />
        </div>
        <div class="content">
          <div class="tabBox">
            <div
              v-for="(key, index) in tabList"
              :key="`tabItem${index}`"
              :class="{'active': currentKey === key.value}"
              class="tabItem"
              @click="tabsChange(key.value)"
            >
              {{ key.label }}
            </div>
          </div>
          <div class="opration">
            <a-button type="link" @click="readAll">全部已读</a-button>
          </div>

          <div class="msgBox">
            <template v-if="loadComplate && newsList.length > 0">
              <div
                v-for="(key, index) in newsList"
                :key="`msgItem${index}`"
                class="msgItem"
                @click="alreadyReadMessage(key.platformMessageReceiveId, key)"
              >
                <div class="left">
                  <div class="msgTitle">{{ key.businessName }}</div>
                  <div class="msgContent">{{ key.content }}</div>
                </div>
                <div class="right">
                  <div v-if="key.readState === '0'" class="circle" />
                  {{ key.createTime }}
                </div>
              </div>
            </template>
            <template v-else>
              <NoData />
            </template>
          </div>
          <a-pagination
            v-if="newsList.length > 0"
            show-quick-jumper
            class="pageNation"
            size="small"
            :default-current="1"
            :current="current"
            :total="total"
            @change="onChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getUserPlatformMessageList, alreadyReadMessage } from '@/services/home/index.js'
export default {
  name: 'BellPopover',
  components: {
    NoData: () => import('@/components/CbNoData')
  },
  data() {
    return {
      userId: JSON.parse(localStorage.getItem(process.env.VUE_APP_USER_KEY)).userId,
      visible: false,
      loadComplate: false,
      // 当前tabs 1：消息通知 2：系统公告
      currentKey: 1,
      pageNum: 1,
      pageSize: 10,
      total: 0,
      current: 1,
      tabList: [
        {
          label: '考勤',
          value: 1
        },
        {
          label: '薪酬',
          value: 2
        },
        {
          label: '社保',
          value: 3
        },
        {
          label: '账号',
          value: 4
        }
      ],
      newsList: []
    }
  },
  methods: {
    show() {
      this.visible = true
      this.init()
    },
    async init() {
      this.loadComplate = false
      const { pageNum, pageSize, currentKey, userId } = this
      const params = {
        userId,
        pageNum,
        pageSize,
        businessType: currentKey
      }
      const res = await getUserPlatformMessageList(params)
      this.newsList = res.data.records
      this.total = res.data.total
      this.current = res.data.current
      this.loadComplate = true
    },
    onChange(pageNum, pageSize) {
      this.pageNum = pageNum
      this.pageSize = pageSize
      this.init()
    },
    async alreadyReadMessage(platformMessageReceiveId, it) {
      const { userId } = this
      await alreadyReadMessage({ platformMessageReceiveId, userId })
      this.pageNum = 1
      if (it.readState === '0') {
        it.readState = '1'
      }
    },
    async readAll() {
      const { userId, currentKey } = this
      await alreadyReadMessage({ userId, businessType: currentKey })
      this.pageNum = 1
      this.init()
    },
    tabsChange(val) {
      this.pageNum = 1
      this.currentKey = val
      this.init()
    }
  }
}
</script>

<style lang="less" scoped>
.modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .45);
  z-index: 2026;
  .main{
    position: fixed;
    top: 100px;
    // left: calc( ( 100vw - 915px ) / 2 );
    left: 50%;
    transform: translateX(-50%);
    width: 915px;
    z-index: 2027;
    background-color: #fff;
    border-radius: 6px;
    max-height: calc(100vh - 10px);
    overflow: auto;
    &::-webkit-scrollbar{
      width: 4px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: @sc-greyBg-20;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0);
      border-radius: 3px;
      background:#fff;
    }
    .headerBox{
      padding: 14px 0;
      text-align: center;
      position: relative;
      margin-bottom: 6px;
      .title{
        font-size: 16px;
        color: @sc-grey-100;
      }
      .close{
        position: absolute;
        right: 25px;
        top: 14px;
        font-size: 14px;
        color: #000;
      }
    }
    .content{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: 0 24px 15px 17px;
      .tabBox{
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px;
        border-radius: 6px;
        border: 1px solid @sc-greyBg-20;
        margin-bottom: 10px;
        .tabItem{
          padding: 2px 40px;
          color: @sc-grey-60;
          font-size: 14px;
          border-radius:4px;
          &:hover{
            cursor: pointer;
            color: @sc-grey-100;
          }
        }
        .active{
          background-color: @sc-greyBg-20;
          color: @sc-grey-100;
          transition: all .9s;
        }
      }
      .opration{
        width: 100%;
        text-align: right;
      }
      .msgBox{
        width: 100%;
        min-height: 300px;
        max-height: 50vh;
        overflow: auto;
        .msgItem{
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          padding: 15px;
          border-bottom: 1px solid @sc-greyBg-20;
          &:hover{
            cursor: pointer;
            background-color: @sc-greyBg-10;
          }
          &:last-child{
            border: none;
          }
          .msgTitle{
            color: @sc-grey-100;
          }
          .msgContent{
            color: @sc-grey-40;
          }
          .right{
            position: relative;
            color: @sc-grey-60;
            .circle{
              position: absolute;
              right: 0;
              top: -8px;
              width: 6px;
              height: 6px;
              background-color: rgba(227, 77, 89, 1);
              border-radius: 50%;
            }
          }
        }
        .pageNation{
          margin-top: 15px;
          text-align: right;
        }
      }
      .pageNation{
          margin-top: 15px;
          // text-align: right;
        }
    }
  }
  // 滚动条
  ::-webkit-scrollbar {
      width: 4px;
    }
  ::-webkit-scrollbar-thumb {
      border-radius: 20px;
      background: @sc-greyBg-20;
    }
  ::-webkit-scrollbar-track {
      display:none;
  }
}
</style>
