<!--
 * @Author: luoyi
 * @Date: 2022-02-22 10:32:12
 * @Last Modified by:   luoyi
 * @Last Modified time: 2022-02-22 10:32:12
-->
<template>
  <div ref="menuLayout" class="menu-layout">
    <div class="left-menu-panel">
      <!-- // 如果是从设置点进来的 则需要显示返回按钮 -->
      <div v-if="getshowSet" class="go-back-menu-btn">
        <div @click="goback">返回</div>
        <div>xxxxx</div>
      </div>
      <slot name="leftTop" />
      <scMenu
        ref="scmenu"
        :indent="'0.1198rem'"
        :data-list="menuDataList"
        :selected-key="selectedKeyInner"
        @change="menuSelectChange"
      >
        <template #menuItem="{data}">
          <div class="left-layout-menu-item">
            <div v-if="data.info[replaceFields.frontIcon]" class="menu-item-icon">
              <svg class="iconpark-icon" :style="{ width:'16px',height:'16px',marginRight:'8px'}">
                <use class="icon-use" :href="`${data.info[replaceFields.frontIcon]}`" />
              </svg>
            </div>
            <div class="menu-item-name" :title="data.info.name">{{ data.info.name }}</div>
            <div class="menu-item-action">
              <a-popover
                v-show="
                  !!data.info[replaceFields.actions] && data.info[replaceFields.actions].length > 0
                "
                :ref="'menupop' + data.info[replaceFields.key]"
                overlay-class-name="menu-popover"
                placement="bottomRight"
                trigger="hover"
                @click.stop="(e) => e.preventDefault()"
              >
                <template slot="content">
                  <div class="popover-action-c">
                    <div
                      v-if="data.info[replaceFields.actions].includes('edit')"
                      class="action-c-btn action-c-edit"
                      @click="menuItemEdit(data.info, data.path)"
                    >
                      编辑
                    </div>
                    <div
                      v-if="data.info[replaceFields.actions].includes('del')"
                      class="action-c-btn action-c-del"
                      @click="menuItemDel(data.info, data.path)"
                    >
                      删除
                    </div>
                  </div>
                </template>
                <a-icon type="ellipsis" />
              </a-popover>
            </div>
          </div>
        </template>
        <template #submenuTitle="{data}">
          <div class="left-layout-submenu-item">
            <div v-if="data.info[replaceFields.frontIcon]" class="menu-submenu-icon">
              <svg class="iconpark-icon" :style="{ width:'16px',height:'16px',marginRight:'8px'}">
                <use class="icon-use" :href="`${data.info[replaceFields.frontIcon]}`" />
              </svg>
            </div>
            <div class="menu-submenu-name menu-submenu-title">{{ data.info.name }}</div>
            <div class="menu-submenu-action">
              <a-popover
                v-show="
                  !!data.info[replaceFields.actions] && data.info[replaceFields.actions].length > 0
                "
                :ref="'menupop' + data.info[replaceFields.key]"
                placement="bottomRight"
                overlay-class-name="menu-popover"
                trigger="hover"
                @click.stop="(e) => e.preventDefault()"
              >
                <template slot="content">
                  <div class="popover-action-c">
                    <div
                      v-if="data.info[replaceFields.actions].includes('edit')"
                      class="action-c-btn action-c-edit"
                      @click="menuItemEdit(data.info, data.path)"
                    >
                      编辑
                    </div>
                    <div
                      v-if="data.info[replaceFields.actions].includes('del')"
                      class="action-c-btn action-c-del"
                      @click="menuItemDel(data.info, data.path)"
                    >
                      删除
                    </div>
                  </div>
                </template>
                <a-icon type="ellipsis" />
              </a-popover>
            </div>
          </div>
        </template>
      </scMenu>
      <slot name="leftBottom" />
    </div>
    <div
      class="right-menu-panel"
      :style="{
        width: `calc(100% - ${
          /^[0-9]+$/.test(leftPanelWidth) ? leftPanelWidth + 'px' : leftPanelWidth
        } - 2px)`,
        minHeight: layoutHeight + 'px'
      }"
    >
      <slot name="default" />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'MenuLayout',
  components: {
    scMenu: () => import('../CbMenu/menu.vue')
  },
  props: {
    leftPanelWidth: {
      // 左侧布局宽度
      type: [Number, String],
      default: '10.1%'
    },
    delMsgInfo: {
      type: Object,
      default: () => {
        return {
          title: '确认删除提示',
          msg: '确认删除当前项吗？'
        }
      }
    },
    menuDataList: {
      // 树状菜单数据
      type: Array,
      default: function() {
        return []
      }
    },
    selectedKey: {
      // 初始选中的菜单项key
      type: [Number, String],
      default: ''
    },
    replaceFields: {
      // 替换dataList中key,children字段为replaceFields的属性
      type: Object,
      default: function() {
        return {
          children: 'children',
          key: 'id',
          frontIcon: 'frontIcon',
          actions: 'actions'
        }
      }
    }
  },
  data() {
    return {
      selectedKeyInner: this.selectedKey,
      currentInfo: {},
      delPath: '',
      layoutHeight: 0 // 容器高度
    }
  },
  computed: {
    ...mapGetters('setting', ['getGlobalMenu_jump', 'getshowSet'])
  },
  watch: {
    'selectedKey': {
      handler: function(val) {
        this.selectedKeyInner = val
        // localStorage.setItem('SELECTKEY', val)
      },
      immediate: true
    },
    'selectedKeyInner': function(val) {
      // 如果从外部修改或
      this.$nextTick(() => {
        if (
          this.$refs.scmenu &&
          JSON.stringify(this.$refs.scmenu.selectedItem) !== '{}' &&
          this.$refs.scmenu.selectedItem[this.replaceFields.key] === val
        ) {
          this.$emit('menu-select-change', this.$refs.scmenu.selectedItem)
          this.$emit('update:selectedKey', val)
        }
        this.$refs.scmenu &&
          localStorage.setItem('SELECTKEYINFO', JSON.stringify(this.$refs.scmenu.selectedItem))
      })
    },
    getGlobalMenu_jump(newVal) {
      this.showInit()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.layoutHeight = this.$refs.menuLayout.clientHeight
    })
  },
  methods: {
    ...mapMutations('setting', ['setShowSet']),
    goback() {
      this.$router.go(-1)
      this.setShowSet()
    },
    showInit(copyIndex = 0) {
      // 全局引导自动跳转路由的逻辑，当route的入参携带globalMenu_jump的时候
      let activeIndex = copyIndex
      let fileName = ''
      let selectedKeyInner = ''
      if (this.getGlobalMenu_jump) {
        localStorage.removeItem('SELECTKEYINFO')
        for (let i = 0; i < this.menuDataList.length; i++) {
          const item = this.menuDataList[i]
          if (item.children && item.children.length > 0) {
            for (let j = 0; j < item.children.length; j++) {
              const key = item.children[j]
              if (key.funcCode === this.getGlobalMenu_jump) {
                fileName = key.fileName
                selectedKeyInner = key[this.replaceFields.key]
                activeIndex = i
                break
              }
            }
          }
          if (item.funcCode === this.getGlobalMenu_jump) {
            fileName = item.fileName
            selectedKeyInner = item[this.replaceFields.key]
            activeIndex = i
            break
          }
        }
      }
      this.$nextTick(() => {
        if (this.menuDataList.length === 0) {
          // 若数组为空，清空缓存
          localStorage.removeItem('SELECTKEYINFO')
        }
        const SELECTKEYINFO = JSON.parse(localStorage.getItem('SELECTKEYINFO') || '{}')
        if (SELECTKEYINFO[this.replaceFields.key]) {
          this.selectedKeyInner = SELECTKEYINFO[this.replaceFields.key]
          this.$emit('update:selectedKey', this.selectedKeyInner)
          this.$emit('menu-select-change', SELECTKEYINFO)
        } else {
          if (this.menuDataList.length === 0) {
            // 当前菜单内容为空
            this.selectedKeyInner = ''
            this.$emit('update:selectedKey', this.selectedKeyInner)
          } else {
            if (!this.menuDataList[activeIndex].children) {
              this.selectedKeyInner = this.menuDataList[activeIndex][this.replaceFields.key]
              this.$emit('update:selectedKey', this.selectedKeyInner)
              this.$emit('menu-select-change', this.menuDataList[activeIndex])
            } else {
              this.selectedKeyInner = selectedKeyInner || this.menuDataList[activeIndex].children[0][this.replaceFields.key]
              this.$emit('update:selectedKey', this.selectedKeyInner)
              this.$emit('menu-select-change', fileName ? { fileName } : this.menuDataList[activeIndex].children[0])
            }
          }
        }
      })
    },
    // 菜单项编辑
    menuItemEdit(info, path) {
      // 点击按钮后让popover自动收起
      this.$emit('menu-action-edit', info, path)
    },
    // 菜单项删除
    menuItemDel(info, path) {
      this.$set(this, 'currentInfo', info)
      this.$set(this, 'delPath', path)
      this.$Reconfirm({
        title: this.delMsgInfo.title,
        content: `确认删除【${this.currentInfo.name}】${this.delMsgInfo.msg}`
      }).then(() => {
        this.handleDel()
      }).catch(() => {
        console.log('cancel')
      })
    },
    // 确认删除
    handleDel() {
      // 点击按钮后让popover自动收起
      const heightLightTarget = ''
      const nextSelectedObject = this.heightLightFunc(
        this.delPath.split('-'),
        this.menuDataList,
        heightLightTarget,
        0
      )
      localStorage.setItem('SELECTKEYINFO', JSON.stringify(nextSelectedObject))
      this.$emit('menu-action-del', this.currentInfo, this.delPath)
    },
    // 菜单项选择
    menuSelectChange(data) {
      this.selectedKeyInner = String(data[this.replaceFields.key])
    },

    /**
     * @description 推荐删除后下一个要高亮的选项，推荐规则：优先推荐同级的下一项，若被删除的是最后一项，则推荐同级第一项，若被删除的是同级唯一项，则推荐上一级项
     * @param path 当前删除的菜单path
     * @param list 菜单项数据
     * @param heightLightTarget
     * @param level 层级，默认为0
     * @param parentTarget 内置父级，不需要传
     * @param search 内置标识，不需要传，如果是true则表示属于查询方法，如果false表示已找到直接返回指定项
     */
    heightLightFunc(path, list, heightLightTarget, level = 0, parentTarget = {}, search = true) {
      // 取当前path当前level下标下的数据对象
      heightLightTarget = list[path[level] - 1] ? list[path[level] - 1] : list[0]
      if (path.length - 1 === level) {
        // 如果是当前层级
        if (heightLightTarget[this.replaceFields.key] === this.selectedKeyInner) {
          // 如果删除项就是选中的，需要查询下一项并返回
          if (list.length === 1) {
            // 当前删除的是当前层级唯一一项
            return parentTarget
          } else {
            // 当前删除的不是当前层级唯一一项，则找下一项
            path[level]++
            return this.heightLightFunc(
              path,
              list,
              heightLightTarget,
              level,
              {
                ...heightLightTarget
              },
              false
            )
          }
        } else if (!search) {
          // search为true，表示此查找项为删除的下一项，直接返回此项即可
          return heightLightTarget
        } else {
          // 删除项不是当前选中的，继续激活当前项即可
          return this.$refs.scmenu.selectedItem || {}
        }
      } else {
        // 去下级（children）中寻找
        return this.heightLightFunc(
          path,
          list[path[level] - 1][this.replaceFields.children],
          heightLightTarget,
          level + 1,
          { ...heightLightTarget }
        )
      }
    }
  }
}
</script>

<style lang="less" scoped>
.menu-layout {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  .left-menu-panel {
    width: 144px;
    height: 100%;
    margin-right: 2PX;
    overflow: hidden;
    background-color: @base-bg-color;
    .go-back-menu-btn {
      font-size: 14px;
      display: flex;
      padding: 20px 16px 0px 16px;
      :last-child {
        margin-left: 10px;
      }
    }
    .sc-menu {
      overflow: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .left-layout-menu-item {
        display: flex;
        align-items: center;
        line-height: 27px;
        .menu-item-icon {
          display: inherit;
        }
        .menu-item-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex-grow: 1;
          .font-size(14px);
        }
        .menu-item-action {
          // display: none;
          margin-right: 16px;
          color: @sc-grey-60;
          cursor: pointer;
          /deep/.anticon.anticon-ellipsis {
            font-size: 16px;
            font-weight: bold;
          }
        }
        .ant-popover-open {
          display: inline !important;
        }
      }
      /deep/.sc-menu-item-hover,
      /deep/.sc-menu-item-active {
        color: @primary-color!important;
        .menu-item-icon {
          .iconpark-icon{
            .icon-use{
              color: @sc-primary-100;
            }
          }
        }
        .menu-item-action {
          // display: block;
          color: @sc-primary-100;
          /deep/.anticon.anticon-ellipsis {
            color: @sc-primary-100;
          }
        }
      }

      .left-layout-submenu-item {
        display: flex;
        align-items: center;
        line-height: 22px;
        .menu-submenu-icon {
          margin-left: 5px;
          display: inherit;
        }
        .menu-submenu-name {
          // margin-left: 8px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          flex-grow: 1;
          .font-size(14px);
        }
        .menu-submenu-title{
          font-size:12px;
        }
        .menu-submenu-action {
          margin-right: 14px;
          cursor: pointer;
          /deep/.anticon.anticon-ellipsis {
            font-size: 16px;
            font-weight: bold;
            color: @sc-grey-60;
          }
        }
        .ant-popover-open {
          display: inline !important;
        }
      }
      /deep/.sc-menu-submenu-hover,
      /deep/.sc-menu-submenu-active {
        color: @primary-color;
        .menu-item-icon {
          .iconpark-icon{
            .icon-use{
              color: @sc-primary-100;
            }
          }
        }
        .menu-submenu-action {
          color: @sc-grey-60;
          /deep/.anticon.anticon-ellipsis {
            display: inline;
            color: @sc-primary-100;
          }
        }
      }
      /deep/.sc-menu-item{
        margin-bottom: 20px;
        color: @sc-grey-60;
        .menu-item-name{
          font-size: 14px !important;
          text-align: left;
        }
      }
    }
  }
  .right-menu-panel {
    position: relative;
    height: 100%;
    background-color: @base-bg-color;
    flex:1
  }
}
</style>
<style lang="less">
.menu-popover {
  padding-top: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner-content {
    padding: 13px;
    .popover-action-c {
      min-width: 40px;
      text-align: center;
      .action-c-btn {
        cursor: pointer;
      }
      .action-c-btn + .action-c-btn {
        margin-top: 5px;
      }
      .action-c-edit{
        color: @sc-primary-100;
      }
      .action-c-del {
        color: @error-color;
      }
    }
  }
}

</style>
