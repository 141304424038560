<!--
 * @Date: 2022-07-04 09:48:19
 * @descriotion:
-->
<template>
  <div>
    <a-modal v-model="visible" title="排班表模板下载" @ok="handleOk" @cancel="cancel">
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="periodId" label="年月" prop="periodId">
          <a-month-picker v-model="form.periodId" placeholder="请选择下载模板月份" :disabled-date="disabledDate" value-format="YYYY-MM" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'AttendanceShift',
  data() {
    return {
      labelCol: { span: 4 },
      wrapperCol: { span: 20 },
      visible: false,
      form: {
        periodId: ''
      },
      rules: {
        periodId: [{ required: true, message: '请选择下载模板月份', trigger: 'change' }]
      }
    }
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.$emit('change', this.form.periodId)
          this.visible = false
        }
      })
    },
    cancel() {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    disabledDate(current) {
      const yearStart = moment().startOf('month')
      const yearEnd = moment().endOf('month').subtract('month', -1).endOf('month')
      return yearStart > current || current >= yearEnd
    }
  }
}
</script>
<style lang="less" scoped>
    /deep/.ant-calendar-picker{
        width:100%;
    }
</style>
